
import { Component, Vue } from 'vue-property-decorator'
import Content from '@/layouts/components/Content.vue'
import BaseTitle from '@/components/base/BaseTitle.vue'

@Component({
  components: {
    Content,
    BaseTitle,
  },
  metaInfo: {
    title: 'Профиль',
  },
})
export default class ProfilePage extends Vue {}
